import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Page, SEO, Container, ArticleCard, Heading } from '../components';
import { MainNavigation } from '../compositions';
import { Wrapper, List, ListItem } from '../compositions/NewsSection/NewsSection.style';

const News = () => {
  const data = useStaticQuery(graphql`
    query allContentfulArticlesQuery {
      allContentfulArticle(filter: {}, sort: { fields: publishDate, order: DESC }) {
        edges {
          node {
            id
            slug
            category
            title
            shortDescription
            image {
              description
              fluid(maxWidth: 350, resizingBehavior: SCALE) {
                src
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
          }
        }
      }
    }
  `);

  const articleArrWithTemplate = data.allContentfulArticle.edges;
  const articleArr = articleArrWithTemplate.filter(article => article.node.slug !== 'how-to-create-an-article');

  return (
    <Page>
      <SEO title="News | Virtual Refugee Conference" />
      <MainNavigation />
      <Wrapper>
        <Container>
          <Heading element="h2" colorKind="light">
            News
          </Heading>
          <List isNewsPage>
            {articleArr.map(item => (
              <ListItem key={item.node.id}>
                <ArticleCard
                  slug={item.node.slug}
                  category={item.node.category}
                  title={item.node.title}
                  shortDescription={item.node.shortDescription}
                  image={item.node.image}
                />
              </ListItem>
            ))}
          </List>
        </Container>
      </Wrapper>
    </Page>
  );
};

export default News;
